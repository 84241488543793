/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ArticleSummary from '../model/ArticleSummary';
import MatomeDetail from '../model/MatomeDetail';
import MatomeSummary from '../model/MatomeSummary';
import MatomeUpdateValues from '../model/MatomeUpdateValues';
import NewMatome from '../model/NewMatome';

/**
* Matome service.
* @module api/MatomeApi
* @version 1.1.74
*/
export default class MatomeApi {

    /**
    * Constructs a new MatomeApi. 
    * @alias module:api/MatomeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 特集（まとめ）の削除
     * @param {Number} matomeId 特集（まとめ）の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteMatomeWithHttpInfo(matomeId) {
      let postBody = null;

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling deleteMatome");
      }


      let pathParams = {
        'matomeId': matomeId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/matomes/{matomeId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）の削除
     * @param {Number} matomeId 特集（まとめ）の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteMatome(matomeId) {
      return this.deleteMatomeWithHttpInfo(matomeId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）に関連する記事の削除
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteMatomeArticleWithHttpInfo(matomeId, articleId) {
      let postBody = null;

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling deleteMatomeArticle");
      }

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling deleteMatomeArticle");
      }


      let pathParams = {
        'matomeId': matomeId,
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/matomes/{matomeId}/articles/{articleId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）に関連する記事の削除
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteMatomeArticle(matomeId, articleId) {
      return this.deleteMatomeArticleWithHttpInfo(matomeId, articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）の詳細
     * 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の特集（まとめ）です。 クライアントが TRILL Admin 以外の場合、予約中の特集（まとめ）は含まれません。 
     * @param {Number} matomeId 特集（まとめ）の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MatomeDetail} and HTTP response
     */
    getMatomeWithHttpInfo(matomeId) {
      let postBody = null;

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling getMatome");
      }


      let pathParams = {
        'matomeId': matomeId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MatomeDetail;

      return this.apiClient.callApi(
        '/matomes/{matomeId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）の詳細
     * 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の特集（まとめ）です。 クライアントが TRILL Admin 以外の場合、予約中の特集（まとめ）は含まれません。 
     * @param {Number} matomeId 特集（まとめ）の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MatomeDetail}
     */
    getMatome(matomeId) {
      return this.getMatomeWithHttpInfo(matomeId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）に関連する記事の一覧
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ArticleSummary>} and HTTP response
     */
    getMatomeArticlesWithHttpInfo(matomeId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling getMatomeArticles");
      }


      let pathParams = {
        'matomeId': matomeId
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage'],
        'startCursor': opts['startCursor'],
        'cursorLimit': opts['cursorLimit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [ArticleSummary];

      return this.apiClient.callApi(
        '/matomes/{matomeId}/articles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）に関連する記事の一覧
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ArticleSummary>}
     */
    getMatomeArticles(matomeId, opts) {
      return this.getMatomeArticlesWithHttpInfo(matomeId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MatomeSummary>} and HTTP response
     */
    getMatomesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage'],
        'startCursor': opts['startCursor'],
        'cursorLimit': opts['cursorLimit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [MatomeSummary];

      return this.apiClient.callApi(
        '/matomes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MatomeSummary>}
     */
    getMatomes(opts) {
      return this.getMatomesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）の更新
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/MatomeUpdateValues} opts.matomeUpdateValues 更新する特集（まとめ）の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MatomeDetail} and HTTP response
     */
    patchMatomeWithHttpInfo(matomeId, opts) {
      opts = opts || {};
      let postBody = opts['matomeUpdateValues'];

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling patchMatome");
      }


      let pathParams = {
        'matomeId': matomeId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MatomeDetail;

      return this.apiClient.callApi(
        '/matomes/{matomeId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）の更新
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/MatomeUpdateValues} opts.matomeUpdateValues 更新する特集（まとめ）の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MatomeDetail}
     */
    patchMatome(matomeId, opts) {
      return this.patchMatomeWithHttpInfo(matomeId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）の追加
     * @param {module:model/NewMatome} newMatome 追加する特集（まとめ）の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MatomeDetail} and HTTP response
     */
    postMatomeWithHttpInfo(newMatome) {
      let postBody = newMatome;

      // verify the required parameter 'newMatome' is set
      if (newMatome === undefined || newMatome === null) {
        throw new Error("Missing the required parameter 'newMatome' when calling postMatome");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MatomeDetail;

      return this.apiClient.callApi(
        '/matomes', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）の追加
     * @param {module:model/NewMatome} newMatome 追加する特集（まとめ）の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MatomeDetail}
     */
    postMatome(newMatome) {
      return this.postMatomeWithHttpInfo(newMatome)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）の復元
     * @param {Number} matomeId 特集（まとめ）の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putMatomeWithHttpInfo(matomeId) {
      let postBody = null;

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling putMatome");
      }


      let pathParams = {
        'matomeId': matomeId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/matomes/{matomeId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）の復元
     * @param {Number} matomeId 特集（まとめ）の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putMatome(matomeId) {
      return this.putMatomeWithHttpInfo(matomeId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 特集（まとめ）に関連する記事の追加
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putMatomeArticleWithHttpInfo(matomeId, articleId) {
      let postBody = null;

      // verify the required parameter 'matomeId' is set
      if (matomeId === undefined || matomeId === null) {
        throw new Error("Missing the required parameter 'matomeId' when calling putMatomeArticle");
      }

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling putMatomeArticle");
      }


      let pathParams = {
        'matomeId': matomeId,
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/matomes/{matomeId}/articles/{articleId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 特集（まとめ）に関連する記事の追加
     * @param {Number} matomeId 特集（まとめ）の ID
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putMatomeArticle(matomeId, articleId) {
      return this.putMatomeArticleWithHttpInfo(matomeId, articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
