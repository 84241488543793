/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import TagBrandInfo from './TagBrandInfo';





/**
* The NewTag model module.
* @module model/NewTag
* @version 1.1.74
*/
export default class NewTag {
    /**
    * Constructs a new <code>NewTag</code>.
    * @alias module:model/NewTag
    * @class
    * @param name {String} 
    */

    constructor(name) {
        


        this['name'] = name;

        
    }

    /**
    * Constructs a <code>NewTag</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewTag} obj Optional instance to populate.
    * @return {module:model/NewTag} The populated <code>NewTag</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewTag();

            
            
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('slug')) {
                obj['slug'] = ApiClient.convertToType(data['slug'], 'String');
            }
            if (data.hasOwnProperty('brandInfo')) {
                obj['brandInfo'] = TagBrandInfo.constructFromObject(data['brandInfo']);
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('exclude')) {
                obj['exclude'] = ApiClient.convertToType(data['exclude'], 'Boolean');
            }
            if (data.hasOwnProperty('dictionary')) {
                obj['dictionary'] = ApiClient.convertToType(data['dictionary'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} slug
    */
    slug = undefined;
    /**
    * @member {module:model/TagBrandInfo} brandInfo
    */
    brandInfo = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {Boolean} exclude
    */
    exclude = undefined;
    /**
    * @member {Boolean} dictionary
    */
    dictionary = undefined;








}


