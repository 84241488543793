/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import AdUnitIdUpdateValues from './AdUnitIdUpdateValues';





/**
* The CategoryUpdateValues model module.
* @module model/CategoryUpdateValues
* @version 1.1.74
*/
export default class CategoryUpdateValues {
    /**
    * Constructs a new <code>CategoryUpdateValues</code>.
    * @alias module:model/CategoryUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>CategoryUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CategoryUpdateValues} obj Optional instance to populate.
    * @return {module:model/CategoryUpdateValues} The populated <code>CategoryUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CategoryUpdateValues();

            
            
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('color')) {
                obj['color'] = ApiClient.convertToType(data['color'], 'String');
            }
            if (data.hasOwnProperty('slug')) {
                obj['slug'] = ApiClient.convertToType(data['slug'], 'String');
            }
            if (data.hasOwnProperty('featured')) {
                obj['featured'] = ApiClient.convertToType(data['featured'], 'Number');
            }
            if (data.hasOwnProperty('adUnitId')) {
                obj['adUnitId'] = AdUnitIdUpdateValues.constructFromObject(data['adUnitId']);
            }
            if (data.hasOwnProperty('cropImage')) {
                obj['cropImage'] = ApiClient.convertToType(data['cropImage'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} color
    */
    color = undefined;
    /**
    * @member {String} slug
    */
    slug = undefined;
    /**
    * @member {Number} featured
    */
    featured = undefined;
    /**
    * @member {module:model/AdUnitIdUpdateValues} adUnitId
    */
    adUnitId = undefined;
    /**
    * このカテゴリの記事のサムネイル・カバーイメージで顔検出・クロッピングするかどうか
    * @member {Boolean} cropImage
    */
    cropImage = undefined;








}


