/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The UserMe model module.
* @module model/UserMe
* @version 1.1.74
*/
export default class UserMe {
    /**
    * Constructs a new <code>UserMe</code>.
    * @alias module:model/UserMe
    * @class
    * @param id {String} 
    * @param username {String} 
    * @param role {String} 
    * @param draftArticleId {Number} 下書き中の記事 ID
    */

    constructor(id, username, role, draftArticleId) {
        


        this['id'] = id;this['username'] = username;this['role'] = role;this['draftArticleId'] = draftArticleId;

        
    }

    /**
    * Constructs a <code>UserMe</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserMe} obj Optional instance to populate.
    * @return {module:model/UserMe} The populated <code>UserMe</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserMe();

            
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
            if (data.hasOwnProperty('draftArticleId')) {
                obj['draftArticleId'] = ApiClient.convertToType(data['draftArticleId'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    id = undefined;
    /**
    * @member {String} username
    */
    username = undefined;
    /**
    * @member {String} role
    */
    role = undefined;
    /**
    * 下書き中の記事 ID
    * @member {Number} draftArticleId
    */
    draftArticleId = undefined;








}


