/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import UserRole from './UserRole';





/**
* The NewUser model module.
* @module model/NewUser
* @version 1.1.74
*/
export default class NewUser {
    /**
    * Constructs a new <code>NewUser</code>.
    * @alias module:model/NewUser
    * @class
    * @param username {String} 
    * @param password {String} 
    * @param role {module:model/UserRole} 
    */

    constructor(username, password, role) {
        


        this['username'] = username;this['password'] = password;this['role'] = role;

        
    }

    /**
    * Constructs a <code>NewUser</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewUser} obj Optional instance to populate.
    * @return {module:model/NewUser} The populated <code>NewUser</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewUser();

            
            
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = UserRole.constructFromObject(data['role']);
            }
        }
        return obj;
    }

    /**
    * @member {String} username
    */
    username = undefined;
    /**
    * @member {String} password
    */
    password = undefined;
    /**
    * @member {module:model/UserRole} role
    */
    role = undefined;








}


