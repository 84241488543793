/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The AuthorizedDigitalSellerVersion model module.
* @module model/AuthorizedDigitalSellerVersion
* @version 1.1.74
*/
export default class AuthorizedDigitalSellerVersion {
    /**
    * Constructs a new <code>AuthorizedDigitalSellerVersion</code>.
    * @alias module:model/AuthorizedDigitalSellerVersion
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>AuthorizedDigitalSellerVersion</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AuthorizedDigitalSellerVersion} obj Optional instance to populate.
    * @return {module:model/AuthorizedDigitalSellerVersion} The populated <code>AuthorizedDigitalSellerVersion</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AuthorizedDigitalSellerVersion();

            
            
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'String');
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = ApiClient.convertToType(data['metadata'], Object);
            }
            if (data.hasOwnProperty('isLatest')) {
                obj['isLatest'] = ApiClient.convertToType(data['isLatest'], 'Boolean');
            }
            if (data.hasOwnProperty('lastModified')) {
                obj['lastModified'] = ApiClient.convertToType(data['lastModified'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} version
    */
    version = undefined;
    /**
    * @member {Object} metadata
    */
    metadata = undefined;
    /**
    * @member {Boolean} isLatest
    */
    isLatest = undefined;
    /**
    * @member {String} lastModified
    */
    lastModified = undefined;








}


