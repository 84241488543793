/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The Views model module.
* @module model/Views
* @version 1.1.74
*/
export default class Views {
    /**
    * Constructs a new <code>Views</code>.
    * @alias module:model/Views
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>Views</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Views} obj Optional instance to populate.
    * @return {module:model/Views} The populated <code>Views</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Views();

            
            
            if (data.hasOwnProperty('ios')) {
                obj['ios'] = ApiClient.convertToType(data['ios'], 'Number');
            }
            if (data.hasOwnProperty('android')) {
                obj['android'] = ApiClient.convertToType(data['android'], 'Number');
            }
            if (data.hasOwnProperty('web')) {
                obj['web'] = ApiClient.convertToType(data['web'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} ios
    */
    ios = undefined;
    /**
    * @member {Number} android
    */
    android = undefined;
    /**
    * @member {Number} web
    */
    web = undefined;








}


