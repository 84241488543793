/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The OriginalArticle model module.
* @module model/OriginalArticle
* @version 1.1.74
*/
export default class OriginalArticle {
    /**
    * Constructs a new <code>OriginalArticle</code>.
    * @alias module:model/OriginalArticle
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>OriginalArticle</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OriginalArticle} obj Optional instance to populate.
    * @return {module:model/OriginalArticle} The populated <code>OriginalArticle</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OriginalArticle();

            
            
            if (data.hasOwnProperty('guid')) {
                obj['guid'] = ApiClient.convertToType(data['guid'], 'String');
            }
            if (data.hasOwnProperty('link')) {
                obj['link'] = ApiClient.convertToType(data['link'], 'String');
            }
            if (data.hasOwnProperty('published')) {
                obj['published'] = ApiClient.convertToType(data['published'], 'String');
            }
            if (data.hasOwnProperty('updated')) {
                obj['updated'] = ApiClient.convertToType(data['updated'], 'String');
            }
            if (data.hasOwnProperty('previewKey')) {
                obj['previewKey'] = ApiClient.convertToType(data['previewKey'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} guid
    */
    guid = undefined;
    /**
    * @member {String} link
    */
    link = undefined;
    /**
    * @member {String} published
    */
    published = undefined;
    /**
    * @member {String} updated
    */
    updated = undefined;
    /**
    * @member {String} previewKey
    */
    previewKey = undefined;








}


