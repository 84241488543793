/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AuthorizedDigitalSeller from '../model/AuthorizedDigitalSeller';
import AuthorizedDigitalSellerVersion from '../model/AuthorizedDigitalSellerVersion';
import InlineResponse201 from '../model/InlineResponse201';
import NewAdsText from '../model/NewAdsText';

/**
* AuthorizedDigitalSeller service.
* @module api/AuthorizedDigitalSellerApi
* @version 1.1.74
*/
export default class AuthorizedDigitalSellerApi {

    /**
    * Constructs a new AuthorizedDigitalSellerApi. 
    * @alias module:api/AuthorizedDigitalSellerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * ads.txt ファイルの情報
     * @param {Object} opts Optional parameters
     * @param {String} opts.version ads.txt ファイルのバージョン
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AuthorizedDigitalSeller} and HTTP response
     */
    getAdsTextWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'version': opts['version']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = AuthorizedDigitalSeller;

      return this.apiClient.callApi(
        '/ads.txt', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ads.txt ファイルの情報
     * @param {Object} opts Optional parameters
     * @param {String} opts.version ads.txt ファイルのバージョン
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AuthorizedDigitalSeller}
     */
    getAdsText(opts) {
      return this.getAdsTextWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ads.txt ファイルのバージョン一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AuthorizedDigitalSellerVersion>} and HTTP response
     */
    getAdsTextVersionsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [AuthorizedDigitalSellerVersion];

      return this.apiClient.callApi(
        '/ads.txt/versions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ads.txt ファイルのバージョン一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AuthorizedDigitalSellerVersion>}
     */
    getAdsTextVersions() {
      return this.getAdsTextVersionsWithHttpInfo()
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ads.txt ファイルを更新する
     * @param {module:model/NewAdsText} newAdsText ads.txt ファイルの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
     */
    patchAdsTextWithHttpInfo(newAdsText) {
      let postBody = newAdsText;

      // verify the required parameter 'newAdsText' is set
      if (newAdsText === undefined || newAdsText === null) {
        throw new Error("Missing the required parameter 'newAdsText' when calling patchAdsText");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = InlineResponse201;

      return this.apiClient.callApi(
        '/ads.txt', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ads.txt ファイルを更新する
     * @param {module:model/NewAdsText} newAdsText ads.txt ファイルの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
     */
    patchAdsText(newAdsText) {
      return this.patchAdsTextWithHttpInfo(newAdsText)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
