/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewNotification from '../model/NewNotification';
import Notification from '../model/Notification';
import NotificationUpdateValues from '../model/NotificationUpdateValues';

/**
* Notification service.
* @module api/NotificationApi
* @version 1.1.74
*/
export default class NotificationApi {

    /**
    * Constructs a new NotificationApi. 
    * @alias module:api/NotificationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * お知らせの削除
     * @param {Number} notificationId お知らせの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteNotificationWithHttpInfo(notificationId) {
      let postBody = null;

      // verify the required parameter 'notificationId' is set
      if (notificationId === undefined || notificationId === null) {
        throw new Error("Missing the required parameter 'notificationId' when calling deleteNotification");
      }


      let pathParams = {
        'notificationId': notificationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/notifications/{notificationId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * お知らせの削除
     * @param {Number} notificationId お知らせの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteNotification(notificationId) {
      return this.deleteNotificationWithHttpInfo(notificationId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * お知らせの詳細
     * @param {Number} notificationId お知らせの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notification} and HTTP response
     */
    getNotificationWithHttpInfo(notificationId) {
      let postBody = null;

      // verify the required parameter 'notificationId' is set
      if (notificationId === undefined || notificationId === null) {
        throw new Error("Missing the required parameter 'notificationId' when calling getNotification");
      }


      let pathParams = {
        'notificationId': notificationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Notification;

      return this.apiClient.callApi(
        '/notifications/{notificationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * お知らせの詳細
     * @param {Number} notificationId お知らせの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notification}
     */
    getNotification(notificationId) {
      return this.getNotificationWithHttpInfo(notificationId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * お知らせの一覧
     * - 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中のお知らせです。   クライアントが TRILL Admin 以外の場合、予約中のお知らせは含まれません。 - クライアントが TRILL Admin 以外の場合、該当しない対象（&#x60;target&#x60;）のお知らせは含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Notification>} and HTTP response
     */
    getNotificationsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage'],
        'startCursor': opts['startCursor'],
        'cursorLimit': opts['cursorLimit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Notification];

      return this.apiClient.callApi(
        '/notifications', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * お知らせの一覧
     * - 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中のお知らせです。   クライアントが TRILL Admin 以外の場合、予約中のお知らせは含まれません。 - クライアントが TRILL Admin 以外の場合、該当しない対象（&#x60;target&#x60;）のお知らせは含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Notification>}
     */
    getNotifications(opts) {
      return this.getNotificationsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * お知らせの更新
     * @param {Number} notificationId お知らせの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/NotificationUpdateValues} opts.notificationUpdateValues 更新するお知らせの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notification} and HTTP response
     */
    patchNotificationWithHttpInfo(notificationId, opts) {
      opts = opts || {};
      let postBody = opts['notificationUpdateValues'];

      // verify the required parameter 'notificationId' is set
      if (notificationId === undefined || notificationId === null) {
        throw new Error("Missing the required parameter 'notificationId' when calling patchNotification");
      }


      let pathParams = {
        'notificationId': notificationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Notification;

      return this.apiClient.callApi(
        '/notifications/{notificationId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * お知らせの更新
     * @param {Number} notificationId お知らせの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/NotificationUpdateValues} opts.notificationUpdateValues 更新するお知らせの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notification}
     */
    patchNotification(notificationId, opts) {
      return this.patchNotificationWithHttpInfo(notificationId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * お知らせの追加
     * @param {module:model/NewNotification} newNotification 追加するお知らせの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notification} and HTTP response
     */
    postNotificationWithHttpInfo(newNotification) {
      let postBody = newNotification;

      // verify the required parameter 'newNotification' is set
      if (newNotification === undefined || newNotification === null) {
        throw new Error("Missing the required parameter 'newNotification' when calling postNotification");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Notification;

      return this.apiClient.callApi(
        '/notifications', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * お知らせの追加
     * @param {module:model/NewNotification} newNotification 追加するお知らせの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notification}
     */
    postNotification(newNotification) {
      return this.postNotificationWithHttpInfo(newNotification)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * お知らせの復元
     * @param {Number} notificationId お知らせの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putNotificationWithHttpInfo(notificationId) {
      let postBody = null;

      // verify the required parameter 'notificationId' is set
      if (notificationId === undefined || notificationId === null) {
        throw new Error("Missing the required parameter 'notificationId' when calling putNotification");
      }


      let pathParams = {
        'notificationId': notificationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/notifications/{notificationId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * お知らせの復元
     * @param {Number} notificationId お知らせの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putNotification(notificationId) {
      return this.putNotificationWithHttpInfo(notificationId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
