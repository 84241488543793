/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The PasswordUpdateValues model module.
* @module model/PasswordUpdateValues
* @version 1.1.74
*/
export default class PasswordUpdateValues {
    /**
    * Constructs a new <code>PasswordUpdateValues</code>.
    * @alias module:model/PasswordUpdateValues
    * @class
    * @param currentPassword {String} 設定されているパスワード
    * @param newPassword {String} 設定するパスワード
    */

    constructor(currentPassword, newPassword) {
        


        this['currentPassword'] = currentPassword;this['newPassword'] = newPassword;

        
    }

    /**
    * Constructs a <code>PasswordUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PasswordUpdateValues} obj Optional instance to populate.
    * @return {module:model/PasswordUpdateValues} The populated <code>PasswordUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PasswordUpdateValues();

            
            
            if (data.hasOwnProperty('currentPassword')) {
                obj['currentPassword'] = ApiClient.convertToType(data['currentPassword'], 'String');
            }
            if (data.hasOwnProperty('newPassword')) {
                obj['newPassword'] = ApiClient.convertToType(data['newPassword'], 'String');
            }
        }
        return obj;
    }

    /**
    * 設定されているパスワード
    * @member {String} currentPassword
    */
    currentPassword = undefined;
    /**
    * 設定するパスワード
    * @member {String} newPassword
    */
    newPassword = undefined;








}


