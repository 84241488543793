/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorscreatorIdThumbnail from './CreatorscreatorIdThumbnail';
import PersonalityQuizQuestionUpdateValues from './PersonalityQuizQuestionUpdateValues';
import PersonalityQuizResultUpdateValues from './PersonalityQuizResultUpdateValues';





/**
* The PersonalityQuizUpdateValues model module.
* @module model/PersonalityQuizUpdateValues
* @version 1.1.74
*/
export default class PersonalityQuizUpdateValues {
    /**
    * Constructs a new <code>PersonalityQuizUpdateValues</code>.
    * @alias module:model/PersonalityQuizUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>PersonalityQuizUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PersonalityQuizUpdateValues} obj Optional instance to populate.
    * @return {module:model/PersonalityQuizUpdateValues} The populated <code>PersonalityQuizUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PersonalityQuizUpdateValues();

            
            
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = CreatorscreatorIdThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = CreatorscreatorIdThumbnail.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('publishDateTime')) {
                obj['publishDateTime'] = ApiClient.convertToType(data['publishDateTime'], 'String');
            }
            if (data.hasOwnProperty('questions')) {
                obj['questions'] = ApiClient.convertToType(data['questions'], [PersonalityQuizQuestionUpdateValues]);
            }
            if (data.hasOwnProperty('results')) {
                obj['results'] = ApiClient.convertToType(data['results'], [PersonalityQuizResultUpdateValues]);
            }
        }
        return obj;
    }

    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {module:model/CreatorscreatorIdThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/CreatorscreatorIdThumbnail} cover
    */
    cover = undefined;
    /**
    * @member {Number} categoryId
    */
    categoryId = undefined;
    /**
    * @member {String} publishDateTime
    */
    publishDateTime = undefined;
    /**
    * @member {Array.<module:model/PersonalityQuizQuestionUpdateValues>} questions
    */
    questions = undefined;
    /**
    * @member {Array.<module:model/PersonalityQuizResultUpdateValues>} results
    */
    results = undefined;








}


