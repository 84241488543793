/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ArticleCover from './ArticleCover';
import ArticleThumbnail from './ArticleThumbnail';





/**
* The OutsourcedArticle model module.
* @module model/OutsourcedArticle
* @version 1.1.74
*/
export default class OutsourcedArticle {
    /**
    * Constructs a new <code>OutsourcedArticle</code>.
    * @alias module:model/OutsourcedArticle
    * @class
    * @param id {Number} 
    * @param articleId {Number} 
    * @param shared {Boolean} 
    * @param updatedAt {String} 
    */

    constructor(id, articleId, shared, updatedAt) {
        


        this['id'] = id;this['articleId'] = articleId;this['shared'] = shared;this['updatedAt'] = updatedAt;

        
    }

    /**
    * Constructs a <code>OutsourcedArticle</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OutsourcedArticle} obj Optional instance to populate.
    * @return {module:model/OutsourcedArticle} The populated <code>OutsourcedArticle</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OutsourcedArticle();

            
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('articleId')) {
                obj['articleId'] = ApiClient.convertToType(data['articleId'], 'Number');
            }
            if (data.hasOwnProperty('shared')) {
                obj['shared'] = ApiClient.convertToType(data['shared'], 'Boolean');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = ArticleThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = ArticleCover.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {Number} articleId
    */
    articleId = undefined;
    /**
    * @member {Boolean} shared
    */
    shared = undefined;
    /**
    * @member {String} comment
    */
    comment = undefined;
    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} summary
    */
    summary = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {module:model/ArticleThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/ArticleCover} cover
    */
    cover = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;








}


